import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";
import Loader from "../loader/Loader";

const EditCommunity = (props) => {
  const [communityData, editCommunityData] = useState({});
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    axios
      .get("community/id?id=" + props.match.params.id)
      .then((data) => {
        editCommunityData(data?.data);
        setisloading(false);
        // console.log("communityData", data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, communityData);
    ud.name_al = e.target.value;
    editCommunityData(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, communityData);
    ud.name_sr = e.target.value;
    editCommunityData(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, communityData);
    ud.name_en = e.target.value;
    editCommunityData(ud);
  };

  const language = (lang) => {
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("community", communityData)
      .then((res) => {
        // console.log(res?.data);
        toast.success("Community has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <>
      <div className="community p-5 text-left">
        <div className="page-title">
          <h1>Edit Community</h1>
        </div>
        {isloading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row ml-2 p-1 mb-5">
              <a
                className={languages == "al" ? "lang-btn-active" : "lang-btn"}
                onClick={() => language("al")}
              >
                Albanian
              </a>
              <a
                className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
                onClick={() => language("sr")}
              >
                Serbian
              </a>
              <a
                className={languages == "en" ? "lang-btn-active" : "lang-btn"}
                onClick={() => language("en")}
              >
                English
              </a>
            </div>

            {languages == "al" && (
              <>
                <input
                  placeholder="Titulli"
                  onChange={name_al}
                  defaultValue={communityData.name_al}
                />
              </>
            )}
            {languages == "sr" && (
              <>
                <input
                  placeholder="Naslov"
                  onChange={name_sr}
                  defaultValue={communityData.name_sr}
                />
              </>
            )}
            {languages == "en" && (
              <>
                <input
                  placeholder="Title"
                  onChange={name_en}
                  value={communityData.name_en}
                />
              </>
            )}

            <button type="submit">Edit Community</button>
          </form>
        )}
      </div>
    </>
  );
};

export default EditCommunity;
