import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axios";
import "./events.scss";
// import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditEvents = (props) => {
  const [eventdata, editeventdata] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);
  const [userroles, adduserroles] = useState([]);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [image, setImage] = useState(null);

  const getCurrentEvent = () => {
    axios
      .get("events/id?id=" + props.match.params.id)
      .then((data) => {
        editeventdata(data?.data);
        // console.log("userdata", data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("users/all")
      .then((data) => {
        adduserroles(data?.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentEvent();
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.name_al = e.target.value;
    editeventdata(ud);
  };

  const name_sr = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.name_sr = e.target.value;
    editeventdata(ud);
  };

  const name_en = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.name_en = e.target.value;
    editeventdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
  };

  const desc_sr = (value) => {
    setContentsr(value);
  };

  const desc_en = (value) => {
    setContenten(value);
  };

  const from_date = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.from_date = e.target.value;
    editeventdata(ud);
  };

  const to_date = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.to_date = e.target.value;
    editeventdata(ud);
  };

  const address = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.address = e.target.value;
    editeventdata(ud);
  };

  const createdBy = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.created_by = e.target.value;
    editeventdata(ud);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    eventdata.desc_al = content;
    eventdata.desc_en = contenten;
    eventdata.desc_sr = contentsr;

    axios
      .put("events", eventdata)
      .then((res) => {
        // console.log(res?.data);
        toast.success("Events has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const imageDataChange = (e) => {
    const ud = Object.assign({}, image);
    ud.pic_url = e.target.files[0];
    setImage(ud);
  };

  const saveImage = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image.pic_url);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("events/image/" + props.match.params.id, formData, config)
      .then((res) => {
        // console.log(res.data);
        toast.success("Image has been changed successfully!");
        getCurrentEvent();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="events p-5 text-left">
      <div className="page-title">
        <h1>Edit Events</h1>
      </div>

      {/* {!isloading ? (
        <Loader />
      ) : ( */}
      <form onSubmit={handleSubmit}>
        <div className="row p-3">
          <img
            src={`${process.env.REACT_APP_UPLOADS + eventdata.pic_url}`}
            alt="Country Image"
            width="200px"
          />
        </div>
        <input type="file" onChange={imageDataChange} />
        {!!image && <button onClick={saveImage}>Save Image</button>}

        <label>From date:</label>
        <input
          type="date"
          onChange={from_date}
          defaultValue={eventdata?.from_date?.slice(0, 10)}
        />

        <label>To date:</label>
        <input
          type="date"
          onChange={to_date}
          defaultValue={eventdata?.to_date?.slice(0, 10)}
        />
        {/* <input
          type="date"
          onChange={createdAt}
          defaultValue={eventdata?.created_at?.slice(0, 10)}
        /> */}

        <input
          type="text"
          onChange={address}
          required
          title="This field is required"
          placeholder="Address"
          defaultValue={eventdata.address}
        />

        <select onChange={createdBy} value={eventdata.created_by}>
          {userroles?.map((el) => {
            return (
              <option value={el?.id} key={el?.id} selected>
                {el?.full_name}
              </option>
            );
          })}
        </select>

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli"
              onChange={name_al}
              defaultValue={eventdata.name_al}
            />
            <ReactQuill
              theme="snow"
              defaultValue={eventdata?.desc_al}
              onChange={desc_al}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        {languages == "sr" && (
          <>
            <input
              placeholder="Naslov"
              onChange={name_sr}
              defaultValue={eventdata.name_sr}
            />
            <ReactQuill
              theme="snow"
              defaultValue={eventdata?.desc_sr}
              onChange={desc_sr}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        {languages == "en" && (
          <>
            <input
              placeholder="Title"
              onChange={name_en}
              value={eventdata.name_en}
            />
            <ReactQuill
              theme="snow"
              defaultValue={eventdata?.desc_en}
              placeholder={"Write something awesome..."}
              onChange={desc_en}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        <button type="submit">Edit Event</button>
      </form>
      {/* )} */}
    </div>
  );
};

export default EditEvents;
