import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import axios from "../../axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
const NewCase = () => {
  const [newcase, setnewcase] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 6;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displaynewcases = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    // console.log(selected);
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get("report-cases/casesWithoutDiscussion")
      .then((res) => {
        setnewcase(res?.data);
        setFiltered(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("NewCasee", newcase)

  return (
    <>
      <div className="row">
        {
          (displaynewcases = filtered
            ?.slice(pagesVisited, pagesVisited + productsPerPage)
            ?.map((el) => {
              return (
                <div className="new-case" key={el.id}>
                  <div className="row case">
                    <b>Case: </b>  {el.case_al?.length >= 80 ?
                      <span>
                        {showMore ? el.case_al : el.case_al?.slice(0, 81) + "..."}
                        <a className="btn" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</a>
                      </span> : <span>{el.case_al}</span>}
                  </div>


                  <div className="row type">
                    <b>Case Address: </b> {el.case_address}
                  </div>
                  <div className="row agency">
                    <b>Agency:</b> {el.name_al}
                  </div>


                  <div className="row contact">
                    <b>Contact: </b> {el.phone}
                  </div>
                  <div className="row contact">
                    <b>Email: </b> {el.email}
                  </div>
                  <div className="row municipality">
                    <b>Case date: </b>  {el.case_date.slice(0, 10)}
                  </div>
                  <div className="row actions ">
                    <Link to={"/report-cases/" + el.id}>
                      {" "}
                      <button className="translate">Discussion</button>
                    </Link>
                  </div>
                  {/* <div className="row actions ">
                    <button className="publish">Publish</button>
                    <button className="translate">Translate</button>
                    <button className="delete">Delete</button>
                  </div> */}
                </div>
              );
            }))
        }
      </div>
      <div className="row mt-5">
        <div className="col-12">
          {newcase.length >= productsPerPage ? (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default NewCase;
