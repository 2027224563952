//import icons from react icons
import {
  FaChartLine,
  FaFolderOpen,
  FaFolder,
  FaUser,
  FaTasks,
  FaCog,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Dashboard",
    icon: <FaChartLine />,
    path: "/",
    className: "nav-text",
  },
  {
    title: "Reported Cases",
    icon: <FaFolderOpen />,
    path: "/showcases",
    className: "nav-text",
  },
  {
    title: "Case Discussions",
    icon: <FaFolder />,
    path: "/casediscussions",
    className: "nav-text",
  },
  {
    title: "Users",
    icon: <FaUser />,
    path: "/users",
    className: "nav-text",
  },
  {
    title: "Web Page Management",
    icon: <FaTasks />,
    path: "#",
    className: "nav-text",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "News",
        path: "/news",
        className: "nav-text",
      },
      {
        title: "Events",
        path: "/events",
        className: "nav-text",
      },
      {
        title: "Legislation",
        path: "/legislations",
      },
      {
        title: "Partners",
        path: "/partners",
      },
      {
        title: "Community",
        path: "/community",
      },
      {
        title: "About Us",
        path: "/community",
      },
      {
        title: "Links",
        path: "/links",
      },
      {
        title: "Mission and Vission",
        path: "/misonidhevizioni",
      },
    ],
  },
  {
    title: "Administration",
    icon: <FaTasks />,
    path: "#",
    className: "nav-text",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Settings",
        path: "/adminsettings",
      },
      {
        title: "Municipalities",
        path: "/municipalities",
      },
      {
        title: "Goverment Agencies",
        path: "/govagencies",
      },
      {
        title: "Report Categories",
        path: "/report-categories",
      },
      {
        title: "Case Status",
        path: "/casestatus",
      },
      {
        title: "Label Translations",
        path: "/labeltranslations",
      },
      {
        title: "Profile",
        path: "/profile",
      },
    ],
  },
];
