import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/Logoshqip.png";
// import { SidebarData } from "./test";
import { useLocation } from "react-router-dom";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

//import icons from react icons
import {
  FaChartLine,
  FaFolderOpen,
  FaFolder,
  FaUser,
  FaTasks,
  FaCog,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaChartArea,
} from "react-icons/fa";
// import { FaChartColumn } from "@react-icons/all-files/fa/FaChartColumn";
import { FiLogOut } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./header.scss";

const Header = ({ menuCollapse, setMenuCollapse, menuIconClick }) => {
  // const [menuCollapse, setMenuCollapse] = useState(false)
  // const menuIconClick = () => {
  //   menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  // }
  const Logout = () => {
    // window.localStorage.removeItem("cd");
    localStorage.clear();
    window.location.reload();
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const role = localStorage.getItem("role");
  console.log("RoliiHeader", role)
  return (
    <>
      {role === "3" ? (
        <div id={!menuCollapse ? "header" : "header1"}>
          <ProSidebar collapsed={menuCollapse}>
            <SidebarHeader>
              <div className={!menuCollapse ? "logotext" : "logotext2"}>
                {/* small and big change using menucollapse state */}
                <Link to="/" >
                  <img src={menuCollapse ? logo : logo} alt="logo" />
                </Link>
              </div>
              <div className="closemenu" onClick={menuIconClick}>
                {/* changing menu collapse icon on click */}
                {menuCollapse ? (
                  <FaChevronCircleRight />
                ) : (
                  <FaChevronCircleLeft />
                )}
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu
                iconShape="none"
                className={!menuCollapse ? "pro-menu vv2" : "vv"}
              >


                <MenuItem
                  // active={window.location.pathname === "/"}
                  // active={"active"}
                  icon={<FaChartLine />}
                  className={splitLocation[1] === "" ? "active " : ""}
                >
                  Dashboard
                  <Link to="/" />
                </MenuItem>
                <MenuItem
                  icon={<FaFolderOpen />}
                  // active={window.location.pathname === "/showcases"}
                  // active={"active"}
                  className={splitLocation[1] === "showcases" ? "active " : ""}
                >
                  Reported Cases <Link to="/showcases" />
                </MenuItem>
                <MenuItem
                  icon={<FaChartArea />}
                  // active={window.location.pathname === "/showcases"}
                  // active={"active"}
                  className={splitLocation[1] === "statistics" ? "active " : ""}
                >
                  Statistics <Link to="/statistics" />
                </MenuItem>
                <MenuItem

                  icon={<CgProfile />}
                  className={splitLocation[1] === "profile" ? "active " : ""}
                >
                  Profile <Link to="/profile" />
                </MenuItem>
              </Menu>
            </SidebarContent>

            <SidebarFooter>
              <Menu iconShape="square">
                <MenuItem icon={<FiLogOut />} onClick={Logout}>
                  Logout
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </ProSidebar>
        </div>
      ) : (
        <div id={!menuCollapse ? "header" : "header1"}>
          <ProSidebar collapsed={menuCollapse}>
            <SidebarHeader>
              <div className={!menuCollapse ? "logotext" : "logotext2"}>
                {/* small and big change using menucollapse state */}
                <Link to="/" >
                  <img src={menuCollapse ? logo : logo} alt="logo" />
                </Link>
              </div>
              <div className="closemenu" onClick={menuIconClick}>
                {/* changing menu collapse icon on click */}
                {menuCollapse ? (
                  <FaChevronCircleRight />
                ) : (
                  <FaChevronCircleLeft />
                )}
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu
                iconShape="none"
                className={!menuCollapse ? "pro-menu vv2" : "vv"}
              >
                {/* {SidebarData?.map((item, index) => {
                return (
                  <>
                    <MenuItem icon={item?.icon}>
                      {item?.title}
                      <Link to={item?.path} />
                    </MenuItem>
                  </>
                );
              })} */}
                <MenuItem
                  // active={window.location.pathname === "/"}
                  // active={"active"}
                  icon={<FaChartLine />}
                  className={splitLocation[1] === "" ? "active " : ""}
                >
                  Dashboard
                  <Link to="/" />
                </MenuItem>
                <MenuItem
                  icon={<FaFolderOpen />}
                  // active={window.location.pathname === "/showcases"}
                  // active={"active"}
                  className={splitLocation[1] === "showcases" ? "active " : ""}
                >
                  Reported Cases <Link to="/showcases" />
                </MenuItem>
                {/* <MenuItem icon={<FaFolder />}>
                Case Discussions <Link to="/casediscussions" />
              </MenuItem> */}
                <MenuItem
                  icon={<FaUser />}
                  className={splitLocation[1] === "users" ? "active " : ""}
                >
                  Users <Link to="/users" />
                </MenuItem>

                <SubMenu
                  title="Web Page Management"
                  icon={<FaTasks />}
                // Link="/cc"
                >
                  <MenuItem
                    className={splitLocation[1] === "news" ? "active " : ""}
                  >
                    News <Link to="/news" />
                  </MenuItem>
                  <MenuItem
                    className={splitLocation[1] === "events" ? "active " : ""}
                  >
                    {" "}
                    Events <Link to="/events" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "legislations" ? "active " : ""
                    }
                  >
                    {" "}
                    Legislation <Link to="/legislations" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "documents_publications" ? "active " : ""
                    }
                  >
                    {" "}
                    Documents and Publications <Link to="/documents_publications" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={splitLocation[1] === "partners" ? "active " : ""}
                  >
                    {" "}
                    Partners <Link to="/partners" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={splitLocation[1] === "community" ? "active " : ""}
                  >
                    {" "}
                    Community <Link to="/community" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={splitLocation[1] === "aboutus" ? "active " : ""}
                  >
                    {" "}
                    About Us <Link to="/aboutus" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={splitLocation[1] === "links" ? "active " : ""}
                  >
                    {" "}
                    Links <Link to="/links" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "misonidhevizioni" ? "active " : ""
                    }
                  >
                    {" "}
                    Mission and Vision <Link to="/misonidhevizioni" />{" "}
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "safety-reports" ? "active " : ""
                    }
                  >
                    {" "}
                    Safety Reports <Link to="/safety-reports" />{" "}
                  </MenuItem>
                </SubMenu>

                <SubMenu title="Administration" icon={<FaCog />}>
                  <MenuItem
                    className={
                      splitLocation[1] === "adminsettings" ? "active " : ""
                    }
                  >
                    Settings <Link to="/adminsettings" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "municipalities" ? "active " : ""
                    }
                  >
                    Municipalities <Link to="/municipalities" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "govagencies" ? "active " : ""
                    }
                  >
                    Government Agencies <Link to="/govagencies" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "report-categories" ? "active " : ""
                    }
                  >
                    Report Categories <Link to="/report-categories" />
                  </MenuItem>
                  <MenuItem
                    className={splitLocation[1] === "casestatus" ? "active " : ""}
                  >
                    Case Status <Link to="/casestatus" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "labeltranslations" ? "active " : ""
                    }
                  >
                    Label Translations <Link to="/labeltranslations" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "terms_conditions" ? "active " : ""
                    }
                  >
                    Terms and Condition <Link to="/terms_conditions" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "privacy_policy" ? "active " : ""
                    }
                  >
                    Privacy Policy <Link to="/privacy_policy" />
                  </MenuItem>
                  <MenuItem
                    className={
                      splitLocation[1] === "safety-of-children" ? "active " : ""
                    }
                  >
                    Safety of children <Link to="/safety-of-children" />
                  </MenuItem>
                </SubMenu>
                <MenuItem

                  icon={<CgProfile />}
                  className={splitLocation[1] === "profile" ? "active " : ""}
                >
                  Profile <Link to="/profile" />
                </MenuItem>
                <MenuItem
                  icon={<FaChartArea />}
                  // active={window.location.pathname === "/showcases"}
                  // active={"active"}
                  className={splitLocation[1] === "statistics" ? "active " : ""}
                >
                  Statistics <Link to="/statistics" />
                </MenuItem>
              </Menu>
            </SidebarContent>

            <SidebarFooter>
              <Menu iconShape="square">
                <MenuItem icon={<FiLogOut />} onClick={Logout}>
                  Logout
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </ProSidebar>
        </div>
      )}

    </>
  );
};

export default Header;
