import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditSafetyReports = (props) => {
  const [safetyReports, setSafetyReports] = useState({});
  const [useroles, adduseroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  // const [files, setFiles] = useState([]);

  useEffect(() => {
    axios
      .get("safety-reports/id?id=" + props.match.params.id)
      .then((data) => {
        setSafetyReports(data?.data);
        // console.log("userdata", data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  return (
    <div className="misioni_vizoni mt-2 text-left ">
      <div className="page-title">
        <h1>View Safety Report</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <div className="row mt-4">
          <div className="col-12">
          {safetyReports?.content}
           
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSafetyReports;
