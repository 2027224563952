import React, { useState, useEffect } from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "../../axios";
import * as XLSX from 'xlsx';

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
)


const Categories = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
      axios
          .get(`statistics/byCategory/${props?.xona}/${props?.xona2}`)
          .then((res) => {
              setCategories(res?.data);
              props?.xona4();
          })
          .catch((err) => {
              console.log(err);
          });
  }, [props?.xona3]);

  let namee = [];
  let number = [];

  categories?.map((el) => {
      namee.push(el.name_al);
      number.push(el.number);
  });

  var data = {
      type: 'bar',
      labels: namee,
      datasets: [{
          label: 'Categories',
          data: number,
          backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
          ],
          borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
          ],
          borderWidth: 2
      }]
  };

  var options = {
      maintainAspectRatio: false,
      borderRadius: 10,
      scales: {
          y: {
              beginAtZero: true
          }
      },
      legend: {
          labels: {
              fontSize: 26
          }
      }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(categories);
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Nga data:', props?.xona]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Deri në datë :', props?.xona2]], { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Categories');
    XLSX.writeFile(workbook, `categories.xlsx`);
};


  return (
      <>
        <div className="chart-title pt-5">
            <h3>Category Chart </h3>
            <button onClick={() => exportToExcel(props?.xona, props?.xona2)} className="excel-btn">
                <i className="fas fa-file-excel pr-2"></i>Export to Excel
            </button>
        </div>
        <div>
            <Bar
                data={data}
                height={400}
                options={options}
            />
        </div>
      </>
  );
};

export default Categories;