import React from "react";

const Search = (props) => {
  let { data,title, filtered, setPageNumber } = props;


  const searcher = async (text) => {
    let searchText = await text?.target.value.toLowerCase();
    let filteredUser = data?.filter((dataCallBack) => {
      if(
          title == 'partners' 
       || title == 'news' 
       || title == 'events'
       || title == 'legislation' 
       || title == 'community'
       || title== 'links' 
       || title == 'municipalities'
       || title == 'govagencies'
       || title == 'ReportCategories'
       )
       { 
      if (dataCallBack?.name_al  ) 
      {
           return dataCallBack?.name_al.toLowerCase().includes(searchText);
           setPageNumber(0);
      }}
      else if(
           title== 'aboutus' 
        || title == 'MisionAndVision' 
        || title == 'TermsCondition' 
        || title == 'PrivacyPolicy'
        || title == 'SafetyOfChildren'
        || title == 'translations')
        {
        if (dataCallBack?.content_al ) {
          return dataCallBack?.content_al.toLowerCase().includes(searchText);
          //  setPageNumber(0);
          }}
      else if(title == 'user'){
          if (dataCallBack?.full_name ) {
              return dataCallBack?.full_name.toLowerCase().includes(searchText);
          //  setPageNumber(0);
          } 
         }
         else if(title == 'documents_publications'){
          if (dataCallBack?.title_al ) {
              return dataCallBack?.title_al.toLowerCase().includes(searchText);
          //  setPageNumber(0);
          } 
         }
       else if(title == 'CaseStatus'){
          if (dataCallBack?.status_al) {
              return dataCallBack?.status_al.toLowerCase().includes(searchText);
              // setPageNumber(0);
            }
          }
        //   if (dataCallBack?.key) {
        //     return dataCallBack?.key.toLowerCase().includes(searchText);
        //     // setPageNumber(0);/
        //   } 
        // 
      });
        filtered(filteredUser);
        setPageNumber(0);
      };
    
      // if (dataCallBack?.name) {
      //   return dataCallBack?.name.toLowerCase().includes(searchText);
      //   // setPageNumber(0);
      // } else if (dataCallBack?.lable_name) {
      //   return dataCallBack?.lable_name.toLowerCase().includes(searchText);
      //   // setPageNumber(0);
      // } else if (dataCallBack?.role_name) {
      //   return dataCallBack?.role_name.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } 
      // else if (dataCallBack?.name_al) {
      //   return dataCallBack?.name_al.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // }
      //  else if (dataCallBack?.name_sr) {
      //   return dataCallBack?.name_sr.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } else if (dataCallBack?.name_en) {
      //   return dataCallBack?.name_en.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } else if (dataCallBack?.status_al) {
      //   return dataCallBack?.status_al.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } else if (dataCallBack?.status_sr) {
      //   return dataCallBack?.status_sr.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } else if (dataCallBack?.status_en) {
      //   return dataCallBack?.status_en.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // }
      //  else
      //   if (dataCallBack?.content_al) {
      //   return dataCallBack?.content_al.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } if (dataCallBack?.content_sr) {
      //   return dataCallBack?.content_sr.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // }  if (dataCallBack?.content_en) {
      //   console.log("english",dataCallBack?.content_en)
      //   return dataCallBack?.content_en.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // } if (dataCallBack?.type) {
      //   return dataCallBack?.type.toLowerCase().includes(searchText);
      //   // setPageNumber(0);/
      // }  
  //     if (dataCallBack?.key) {
  //       return dataCallBack?.key.toLowerCase().includes(searchText);
  //       // setPageNumber(0);/
  //     } 
  //     // else if (dataCallBack?.name_al) {
  //     //     return dataCallBack?.name_al.toLowerCase().includes(searchText);
  //     //      setPageNumber(0);}
  //     // else {
  //     //   return dataCallBack?.full_name.toLowerCase().includes(searchText);
  //     //   // setPageNumber(0);
  //     // }
  //   });
  //   filtered(filteredUser);
  //   setPageNumber(0);
  // };

  return (
    <div className="col-lg-6 col-12 mb-3 search-form">
      {/* <label>Search</label> */}
      {/* <select required title="This field is required">
        <option value={1}>Sort</option>

        <option value={2}>dhdjkagh</option>
      </select>
      <select required title="This field is required">
        <option value={1}>Status</option>

        <option value={2}>dhdjkagh</option>
      </select> */}

      <input
        type="text"
        onChange={searcher}
        className="form-control search-input"
        placeholder="Search..."
        aria-label="Search"
      />
    </div>
  );
};

export default Search;
