import React, { useState } from "react";
import axios from "../../axios";
import Logo from "../../assets/tdh-logo.png";
import jwt from "jwt-decode";
import "./login.scss";

const Login = (props) => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  console.log("PTOPDSAA", props)
  const email = (e) => {
    const ud = Object.assign({}, userData);
    ud.email = e.target.value;
    setUserData(ud);
  };
  const password = (e) => {
    const ud = Object.assign({}, userData);
    ud.pass = e.target.value;
    setUserData(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.email && userData.pass) {
      axios
        .post("/login", userData)
        .then((res) => {
          if (res.data.token) {
            setErrors(false);
            window.localStorage.setItem("cd", res.data.token);
            const user = jwt(res.data.token);
            console.log("userhhhfgsdhf", user)
            window.localStorage.setItem("role", user.role);
            window.localStorage.setItem("id", user.id);
            window.location = "/";
          } else {
            setErrors(res.data.error);
          }
        })
        .catch((err) => {
          console.log("error incoming", err);
          setLoginErrorMessage(err?.response?.data?.error);
        });
    } else {
      setErrors(true);
    }
  };

  return (
    <div className="login">
      <div className="row text-danger pl-5"></div>
      <div className="login-logo">
        <img src={Logo} alt="Logo" width="300px" />
      </div>
      <div className="login-wraper">
        <form onSubmit={handleSubmit}>
          <input type="email" placeholder="Email Address" onChange={email} />
          <input type="password" placeholder="Password" onChange={password} />
          <button type="submit">Login</button>
        </form>
        <div className="text-center text-danger">{loginErrorMessage}</div>
      </div>
    </div>
  );
};

export default Login;
