import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteUser = (props) => {
  const [userdata, adduserdata] = useState({});
  const [languages, addlanguages] = useState({});
  const [labels, addlabels] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("users/" + props.match.params.id)
      .then((data) => {
        adduserdata(data?.data);
        // console.log("user123", data);
        setisloading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("users/?id=" + userdata.id)
      .then((data) => {
        toast.success(`User ${userdata?.full_name} was deleted successfully`);
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  // console.log(languages);
  // console.log("jsdjsdfhsjdfhsd", userdata);

  return (
    <div className="users p-5 text-left">
      <div className="page-title">
        <h1>Delete User</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete user with the following
        data:
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <input type="text" defaultValue={userdata?.full_name} disabled />
          {/* <input type="text" defaultValue={userdata?.email} disabled /> */}
          <input
            type="text"
            // value={userdata.active ? "Active" : "Passive"}
            defaultValue={userdata?.active == 1 ? "Active" : "Passive"}
            disabled
          />
          <input
            type="text"
            // value={userdata.role == 1 ? "Admin" : "Client"}
            defaultValue={userdata?.role == 1 ? "Admin" : "Client"}
            disabled
          />

          <button type="submit">Delete User</button>
        </form>
      )}
    </div>
  );
};
export default DeleteUser;
