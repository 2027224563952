import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";
import "./admin_settings.scss";

const DeleteAdminSetting = (props) => {
  const [settings, deletesetting] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("admin-settings/id?id=" + props.match.params.id)
      .then((data) => {
        deletesetting(data?.data);
        setisloading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("admin-settings/?id=" + settings.id)
      .then((data) => {
        toast.success(`Setting ${settings.option} was deleted successfully`);
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  return (
    <div className="admin_settings p-5 text-left">
      <div className="page-title">
        <h1>Delete Setting</h1>
      </div>

      <div className="row mb-3 text">
        By clicking delete button you agree to delete settings with the
        following data:
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Option</label>
          <input type="text" defaultValue={settings.option} disabled />
          <label>Type</label>
          <input type="text" defaultValue={settings.type} disabled />
          <label>Value</label>
          <input type="text" defaultValue={settings.value} disabled />
          <label>Content</label>
          <input type="text" defaultValue={settings.content} disabled />
          <button type="submit">Delete Setting</button>
        </form>
      )}
    </div>
  );
};
export default DeleteAdminSetting;
