import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";
import "./gov_agencies.scss";

const DeleteGovAgencies = (props) => {
  const [govagency, addgovagency] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("gov-agencies/id?id=" + props.match.params.id)
      .then((data) => {
        addgovagency(data.data);
        setisloading(true);
      })

      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .delete("gov-agencies/?id=" + govagency.id)
      .then((data) => {
        toast.success(`Agency ${govagency.name_en} was deleted successfully`);
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  return (
    <div className="govagencies p-5 text-left">
      <div className="page-title">
        <h1>Delete Gov. Agency</h1>
      </div>

      <div className="row mb-3 text">
        By clicking delete button you agree to delete governement agency with
        the following data:
      </div>

      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Type</label>
          <input type="text" defaultValue={govagency.type} disabled />
          <label>Albanian</label>
          <input type="text" defaultValue={govagency.name_al} disabled />
          <label>Serbian</label>
          <input type="text" defaultValue={govagency.name_sr} disabled />
          <label>English</label>
          <input type="text" value={govagency.name_en} disabled />

          <button type="submit">Delete Agency</button>
        </form>
      )}
    </div>
  );
};
export default DeleteGovAgencies;
