import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axios";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditNews = (props) => {
  const [newsData, setNewsData] = useState({});
  const [languages, setLanguages] = useState("al");
  const [isLoading, setIsLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [content, setContent] = useState({
    al: "",
    sr: "",
    en: "",
  });
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const newsResponse = await axios.get(
          `/news/id?id=${props.match.params.id}`
        );
        const userResponse = await axios.get(
          `/users/${window.localStorage.getItem("id")}`
        );
        const userRolesResponse = await axios.get("/users/all");

        setUser(userResponse?.data);
        setNewsData(newsResponse?.data);
        setUserRoles(userRolesResponse?.data);
        setIsLoading(true);
      } catch (error) {
        toast.error("Something went wrong!");
        console.error(error);
      }
    };

    fetchNewsData();
  }, [props.match.params.id]);

  const handleChange = (field, value) => {
    setNewsData({ ...newsData, [field]: value });
  };

  const handleLanguageChange = (lang) => {
    setLanguages(lang);
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleVideoChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleDescChange = (lang, value) => {
    setContent({ ...content, [lang]: value });
  };

  const created_by = (e) => {
    const ud = Object.assign({}, newsData);
    ud.created_by = e.target.value;
    setNewsData(ud);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("id", props.match.params.id);
      formData.append("name_al", newsData.name_al);
      formData.append("name_sr", newsData.name_sr);
      formData.append("name_en", newsData.name_en);
      formData.append("desc_al", content.al || newsData.desc_al);
      formData.append("desc_sr", content.sr || newsData.desc_sr);
      formData.append("desc_en", content.en || newsData.desc_en);
      formData.append("created_by", newsData.created_by);
      formData.append("news_date", newsData.news_date);

      if (imageFile) {
        formData.append("image", imageFile);
      }

      if (videoFile) {
        formData.append("video", videoFile);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const response = await axios.put(
        `/news/${props.match.params.id}`,
        formData,
        config
      );

      if (response.status === 200) {
        toast.success("News has been edited successfully!");
        window.history.back();
      } else {
        toast.error("Failed to edit news.");
      }
    } catch (error) {
      toast.error("Failed to edit news.");
      console.error(error);
    }
  };

  if (!isLoading) {
    return <Loader />;
  }

  return (
    <div className="news p-5 text-left">
      <div className="page-title">
        <h1>Edit News</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row p-3">
          <img
            src={
              imageFile
                ? URL.createObjectURL(imageFile)
                : `${process.env.REACT_APP_UPLOADS}${newsData.pic_url}`
            }
            alt="News Image"
            width="100px"
          />
        </div>
        <label>Upload photo:</label> 

        <input type="file" onChange={handleImageChange} />

    
        <div className="row p-3">
          {newsData.video_url || videoFile ? (
            <video width="300" controls>
              <source
                src={
                  videoFile
                    ? URL.createObjectURL(videoFile)
                    : `${process.env.REACT_APP_UPLOADS}${newsData.video_url}`
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : null}
        </div>
        <label>Upload video:</label> 
        <input type="file" onChange={handleVideoChange} />

        <input
          type="date"
          defaultValue={newsData?.news_date?.slice(0, 10)}
          onChange={(e) => handleChange("news_date", e.target.value)}
        />

        <select onChange={created_by} defaultValue={newsData.created_by}>
          <option value={user?.id}>{user?.full_name}</option>
          {/* {userroles?.map((el) => {
              return (
                <option value={el?.id} key={el?.id} selected>
                  {el?.full_name}
                </option>
              );
            })} */}
        </select>

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages === "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => handleLanguageChange("al")}
            type="button"
          >
            Albanian
          </a>
          <a
            className={languages === "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => handleLanguageChange("sr")}
            type="button"
          >
            Serbian
          </a>
          <a
            className={languages === "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => handleLanguageChange("en")}
            type="button"
          >
            English
          </a>
        </div>

        {languages === "al" && (
          <>
            <input
              placeholder="Titulli"
              value={newsData.name_al}
              onChange={(e) => handleChange("name_al", e.target.value)}
            />
            <ReactQuill
              theme="snow"
              defaultValue={newsData.desc_al}
              onChange={(value) => handleDescChange("al", value)}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        {languages === "sr" && (
          <>
            <input
              placeholder="Naslov"
              value={newsData.name_sr}
              onChange={(e) => handleChange("name_sr", e.target.value)}
            />
            <ReactQuill
              theme="snow"
              defaultValue={newsData.desc_sr}
              onChange={(value) => handleDescChange("sr", value)}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        {languages === "en" && (
          <>
            <input
              placeholder="Title"
              value={newsData.name_en}
              onChange={(e) => handleChange("name_en", e.target.value)}
            />
            <ReactQuill
              theme="snow"
              defaultValue={newsData.desc_en}
              onChange={(value) => handleDescChange("en", value)}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        <button type="submit">Edit News</button>
      </form>
    </div>
  );
};

export default EditNews;
