import React, { useState, useEffect } from "react";
import CaseDiscussion from "../CaseDiscussion/case_discussion";
import ReportCases from "../reported_cases/reported_cases";
import "./show_case.scss";
import ReactPaginate from "react-paginate";
import axios from "../../axios";
import Loader from "../loader/Loader";
import * as XLSX from 'xlsx';


const myDate = new Date();
const dateToday = new Date().toISOString();
const d = new Date(dateToday);
const palidhje = d.setMonth(d.getMonth() - 3)
const dateMonthAgo = new Date(palidhje).toISOString();

const ShowCases = (props) => {
  const [data, setdata] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isReport, setisReport] = useState(true);
  const [isDiscussion, setisDiscussion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const showReports = (e) => {
    setisDiscussion(false);
    setisReport(true);
  };
  const showDiscussions = (e) => {
    setisReport(false);
    setisDiscussion(true);
  };
  const [start, setStart] = useState(dateMonthAgo?.slice(0, 10));
  const [end, setEnd] = useState(dateToday?.slice(0, 10));
  const [search, setSearch] = useState(false);


  useEffect(() => {
    axios

      .get(`report-cases/${start}/${end}`)
      .then((res) => {
        setdata(res?.data);
        setFiltered(res?.data);
        setSearch(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 300);

  }, []);

  const dateStart = (e) => {
    setStart(e.target.value);
  }
  const dateEnd = (e) => {
    setEnd(e.target.value);
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Nga data:', dateMonthAgo?.slice(0, 10)]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Deri në datë :', dateToday?.slice(0, 10)]], { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'caseDiscussion');
    XLSX.writeFile(workbook, `caseDiscussion.xlsx`);
};

  if (isLoading === true) {
    return <Loader />;
  }
  return (
    <div className="show-case text-left">
      <div className="row  grid">
        <div className="col-2">
          <i
            className={
              isReport
                ? "modal-button active-modal far fa-th-large "
                : "modal-button fas fa-th-large "
            }
            onClick={showReports}
          ></i>
          <i
            className={
              isDiscussion
                ? "modal-button active-modal fas  far fa-list-alt"
                : "modal-button far  fa-list-alt"
            }
            onClick={showDiscussions}
          ></i>
        </div>
        <div className="col-8">
          <div className='date-set'>
            From:
            <input type={'date'} onChange={dateStart} defaultValue={dateMonthAgo?.slice(0, 10)}></input>
            To:
            <input type={'date'} onChange={dateEnd} defaultValue={dateToday?.slice(0, 10)}></input>
            <button onClick={() => setSearch(true)} >Search</button>
          </div>
        </div>
        <div className="col-2">
          {data?.length !== 0
          ?
          <button onClick={() => exportToExcel()} className="btn btn-outline-success">
                <i className="fas fa-file-excel pr-2"></i>Export to Excel
            </button>
          : ""  
           }
        </div>
      </div>
      <div className="row w-100 mt-4 cases">
        {/* show reports */}
        {isDiscussion && (filtered.length > 0 ? (
          <div className="reports-dashboard">
            <ReportCases xona4={filtered} xona3={data} />
          </div>
        ) : (
          <p>
            There are 0 cases!
          </p>
        ))}

        {isReport && (filtered.length > 0 ? (
          <div className="reports-dashboard">
            <CaseDiscussion xona={filtered} xona2={data} />
          </div>
        ) : (
          <p>
            There are 0 cases!
          </p>
        ))}

        {/* {isReport && (
          <div className="reports-dashboard">
            <CaseDiscussion xona = {filtered} xona2={data}/>
          </div>
        )} */}
        {/* {isDiscussion && <ReportCases xona4={filtered} xona3={data} />} */}
      </div>
    </div>
  );
};

export default ShowCases;
