import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./admin_settings.scss";

const EditAdminSettings = (props) => {
  const [settings, addsettings] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("admin-settings/id?id=" + props.match.params.id)
      .then((data) => {
        addsettings(data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const option = (e) => {
    const ud = Object.assign({}, settings);
    ud.option = e.target.value;
    addsettings(ud);
  };
  const type = (e) => {
    const ud = Object.assign({}, settings);
    ud.type = e.target.value;
    addsettings(ud);
  };
  const value = (e) => {
    const ud = Object.assign({}, settings);
    ud.value = e.target.value;
    addsettings(ud);
  };
  const content = (e) => {
    const ud = Object.assign({}, settings);
    ud.content = e.target.value;
    addsettings(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("admin-settings", settings)
      .then((data) => {
        toast.success(`Setting ${settings.tyle} has been edited successfully!`);
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="admin_settings p-5 text-left">
      <div className="page-title">
        <h1>Edit Setting</h1>
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Option</label>
          <input
            type="text"
            placeholder="type"
            onChange={option}
            defaultValue={settings.option}
          ></input>
          <label>Type</label>
          <input
            type="text"
            placeholder="type"
            onChange={type}
            defaultValue={settings.type}
          ></input>
          <label>Value</label>
          <input
            type="text"
            placeholder="value"
            onChange={value}
            defaultValue={settings.value}
          ></input>
          <label>Content</label>
          <input
            type="text"
            placeholder="content"
            onChange={content}
            defaultValue={settings.content}
          ></input>
          <button type="submit">Edit Setting</button>
        </form>
      )}
    </div>
  );
};
export default EditAdminSettings;
