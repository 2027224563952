import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { SendOutlined } from "@ant-design/icons";
import axios from "../../axios";
import "./case_discussion.scss";
import Modal from 'react-modal';
import ShowFiles from "../ShowFiles/showfiles";
import ShowFilesModal from "../ShowFilesModal/modal";
import ShowStatusModal from "../ShowStatusModal/ShowStatusModal";
import ShowEditModal from "../EditModal/ShowEditModal";
import ShowDeleteModal from "../DeleteModal/DeleteModal";
import Loader from "../loader/Loader";

// import { render } from '@testing-library/react'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: "translate(-50%, -56%)",
    width: "700px",
    height: "600px",
    background: "rgb(251 251 251)"
  },

};
const StatusModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: "translate(-50%, -66%)",
    width: "700px",
    height: "400px",
    background: "rgb(251 251 251)"

  },
};

const Discussion = (props) => {
  const [discussion, setdiscussion] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [status, setStatus] = useState([]);
  const [comments, setcomments] = useState([]);
  const [postcomment, setpostcomment] = useState("");
  const [user, setUser] = useState();
  const [edit, setEdit] = useState([]);
  // MODAL
  const [modalIsOpen, setIsOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dataId, setDataId] = useState();
  const [showMore, setShowMore] = useState(false);
  const [disable, setDisable] = useState(false);
  const [postMessageText, setpostMessageText] = useState(false);


  const changeDisableState = () => {
    setDisable(!disable);
  };
  const role = localStorage.getItem("role");
  console.log("Rolii", role)
  const { id } = useParams();
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function openStatusModal() {
    setStatusModalOpen(true);
  }
  function closeModalStatus() {
    setStatusModalOpen(false);
  }
  function openEditModal(id) {
    setEditModalOpen(true);
    setDataId(id);
  }
  function closeEditModal() {
    setEditModalOpen(false);
  }

  function openDeleteModal(id) {
    setDeleteModalOpen(true);
    setDataId(id);
  }
  function closeDeleteModal() {
    setDeleteModalOpen(false);
  }
  useEffect(() => {
    axios
      .get("report-cases/" + id)
      .then((res) => {
        setdiscussion(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/case-status/all`)
      .then((res) => {
        setStatus(res.data);
        // console.log('statusData', res.data);
      })
      .catch((err) => {
        console.log(err);
      });



    axios
      .get(`case-discussion/case_id/${id}`)
      .then((res) => {
        setcomments(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [postMessageText]);
  // old post
  // }, [postcomment]);
  // console.log("CaseStatus", caseStatus)
  const getSocialMedia = async () => {
    axios
      .get(`/case-statuses/case/id?id=` + id)
      .then((res) => {
        setCaseStatus(res?.data);
        // setFiltered(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const content_al = (e) => {
    setpostcomment(e.target.value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    let dataa = {
      uuid: window.localStorage.getItem("id"),
      case_id: id,
      content_al: postcomment,
    };

    axios
      .post("case-discussion", dataa)
      .then((data) => {
        // console.log('dataa', data)
        setpostcomment("");
        setpostMessageText(!postMessageText);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {

    getSocialMedia();
  }, [])
  useEffect(() => {
    axios
      .get("users/" + window.localStorage.getItem("id"), {
        // headers: {
        //   Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        // },
      })
      .then((response) => {
        setUser(response);
        // console.log("usertest", response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log("djfajksfd", discussion);

  return (
    <div className="case-discussion">

      <div className=" w-100 cases_d">
        <div className="new-discussion col-md-12">
          <div className=" row chat_info">
            <div className="info col-4 text-left">

              <p>
                <b>Agency: </b>
                {discussion?.gov_al}
              </p>
              <p>
                <b>Municipality:</b> {discussion?.municipality_al}
              </p>
              <p>
                <b>Contact:</b> {discussion?.phone}
              </p>
              <p>
                <b>Email:</b> {discussion?.email}
              </p>
              <div className="foto">
                <b>Img/Video:</b>

                {/* <img
                src={`${process.env.REACT_APP_UPLOADS + discussion?.pic_url}`}
                width="50px"
                height="50px"
                download
              /> */}
                {/* <Link
              
                // href={`${process.env.REACT_APP_UPLOADS + discussion.pic_url}`}
                to={`/showfiles/${id}`}
                // target="_blank"
              >
              
              Open
              </Link> */}
                <span onClick={openModal} className="text-info text-decoration-none" style={{cursor:"pointer"}}>
                  <i  class="fa fa-file-image pl-1 pr-1" />
                  Open files
                </span>
              </div>
            </div>

            {role === "3" ? (
              <div className="info col-8">
                <div className="row">
                  <div className="col-3 text-left">
                    <span><b>Date:</b>
                    </span>
                  </div>
                  <div className="col-2 text-left">
                    <span><b>Status:</b>
                    </span>
                  </div>
                  <div className="col-2 text-left">
                    <span><b>Description:</b>
                    </span>
                  </div>
                  <div className="col-5 text-right">

                  </div>
                </div>

                <div className="listStatuses text-left row">
                  {caseStatus.map((el, index) => {
                    return (<>
                      {/* <div className="statusItem">{el.date.slice(0, 10) + '   | ' + el.status_id + ' | ' + el.description} </div> */}

                      <div className="col-3 statusItem">
                        {el.date.slice(0, 10)}
                      </div>
                      <div className="col-2 statusItem text-left">
                        {/* {el.status_id === status.id ? status?.status_al : el.status_id} */}
                        {status?.find(elem => elem.id === el.status_id)?.status_al}
                      </div>
                      {/* {user?.data?.full_name} */}
                      <div className="col-5 text-left statusItem">
                        {el.description}
                      </div>
                      <div className="col-1 text-left statusItem" >
                        <div className="col-1 edit">
                          <Link
                            className={"fa fa-edit text-dark statusItemdisabled"} />
                          {/* <div className='fa fa-edit' onClick={(e) => {
                          e.preventDefault();
                          render(<ShowEditModal closeEditModal={closeEditModal} data={caseStatus} id={el.id} />
                          );
                        }}>
                        </div> */}
                        </div>
                      </div>
                      <div
                        className={"col-1 text-left statusItem"} >
                        <div className="col-1 edit">
                          <Link
                            className={"fa fa-trash text-dark statusItemdisabled"} />
                          {/* <div className='fa fa-edit' onClick={(e) => {
                          e.preventDefault();
                          render(<ShowEditModal closeEditModal={closeEditModal} data={caseStatus} id={el.id} />
                          );
                        }}>
                        </div> */}
                        </div>
                      </div>
                    </>
                    )
                  })}
                </div>
              </div>) : (<div className="info col-8">
                <div className="row">
                  <div className="col-3 text-left">
                    <span><b>Date:</b>
                    </span>
                  </div>
                  <div className="col-2 text-left">
                    <span><b>Status:</b>
                    </span>
                  </div>
                  <div className="col-2 text-left">
                    <span><b>Description:</b>
                    </span>
                  </div>
                  <div className="col-5 text-right">
                    <span onClick={openStatusModal} className="text-dark" style={{cursor:"pointer"}}>
                      <i className="fa fa-plus pr-1 text-decoration-none text-dark"></i>Add New Status
                    </span>
                  </div>
                </div>

                <div className="listStatuses text-left row">
                  {caseStatus.map((el, index) => {
                    return (<>
                      {/* <div className="statusItem">{el.date.slice(0, 10) + '   | ' + el.status_id + ' | ' + el.description} </div> */}

                      <div className="col-3 statusItem">
                        {el.date.slice(0, 10)}
                      </div>
                      <div className="col-2 statusItem text-left">
                        {/* {el.status_id === status.id ? status?.status_al : el.status_id} */}
                        {status?.find(elem => elem.id === el.status_id)?.status_al}
                      </div>
                      {/* {user?.data?.full_name} */}
                      <div className="col-5 text-left statusItem">
                        {el.description}
                      </div>
                      <div className="col-1 text-left statusItem">
                        <div className="col-1 edit">
                          <span onClick={() => { openEditModal(el.id) }}
                            className={"fa fa-edit text-dark"} style={{cursor:"pointer"}} />
                          {/* <div className='fa fa-edit' onClick={(e) => {
                          e.preventDefault();
                          render(<ShowEditModal closeEditModal={closeEditModal} data={caseStatus} id={el.id} />
                          );
                        }}>
                        </div> */}
                        </div>
                      </div>
                      <div className="col-1 text-left statusItem">
                        <div className="col-1 edit">
                          <Link onClick={() => { openDeleteModal(el.id) }}
                            className={"fa fa-trash text-dark"} />
                          {/* <div className='fa fa-edit' onClick={(e) => {
                          e.preventDefault();
                          render(<ShowEditModal closeEditModal={closeEditModal} data={caseStatus} id={el.id} />
                          );
                        }}>
                        </div> */}
                        </div>
                      </div>
                    </>
                    )
                  })}
                </div>
              </div>)}
            <div className="case-title">
              <span class="text-dark pr-2 font-weight-bold">Case:</span>
              {/* <h5>{discussion.case_al?.slice(0, 224) + "..."}</h5> */}
              {discussion.case_al?.length >= 200 ?
                <span>
                  {showMore ? discussion.case_al : discussion.case_al?.slice(0, 224) + "..."}
                  <button className="showMore" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button>
                </span> : <span>{discussion.case_al}</span>}
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <ShowFilesModal closeModal={closeModal} />
          </Modal>

          <Modal
            isOpen={statusModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModalStatus}
            style={StatusModalStyle}
            contentLabel="Example Modal">
            <ShowStatusModal closeModalStatus={closeModalStatus} getStatus={getSocialMedia} />
          </Modal>
          <Modal
            isOpen={EditModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeEditModal}
            style={StatusModalStyle}
            contentLabel="Example Modal">
            <ShowEditModal closeEditModal={closeEditModal} data={caseStatus} id={dataId} />
          </Modal>
          <Modal
            isOpen={DeleteModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeDeleteModal}
            style={StatusModalStyle}
            contentLabel="Example Modal">
            <ShowDeleteModal closeDeleteModal={closeDeleteModal} data={caseStatus} id={dataId} />
          </Modal>
          <div className="messages">
            {comments?.map((el) => {
              return (
                <div className="discussions" key={el.id}>
                  {el.uuid == "1" ? (
                    <div className="thread">
                      <div className="row case" id="chatList">
                        {el.content_al}
                      </div>
                    </div>
                  ) : (
                    <div className="reply-comment">
                      <div className="row">
                        <div className="icon">
                          {/* <i className="fas fa-comment"></i> */}
                        </div>
                        <div className="content">{el.content_al}</div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="post_message col-md-12">
          <form onSubmit={handleSubmit} className="message-form">
            {role === "3" ? (
              < input
                type="text"
                // onChange={content_al}
                disabled
                className="message-input"
              // placeholder="Send a message..."
              // value={postcomment}

              />
            ) : (
              <input
                type="text"
                onChange={content_al}
                required
                className="message-input"
                placeholder="Send a message..."
                value={postcomment}
              />
            )}

            <button type="submit">
              <SendOutlined className="send-icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Discussion;
