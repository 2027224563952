import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const AddPartners = () => {
  const [partnersdata, addpartnersdata] = useState({});
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [editordata, seteditordata] = useState("");
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [image, setImage] = useState(null);

  const name_al = (e) => {
    const ud = Object.assign({}, partnersdata);
    ud.name_al = e.target.value;
    addpartnersdata(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, partnersdata);
    ud.name_sr = e.target.value;
    addpartnersdata(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, partnersdata);
    ud.name_en = e.target.value;
    addpartnersdata(ud);
  };
  const desc_al = (value) => {
    setContent(value);
  };
  const desc_sr = (value) => {
    setContentsr(value);
  };
  const desc_en = (value) => {
    setContenten(value);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };
  const imageDataChange = (e) => {
    let img = e.target.files[0];
    setImage(img);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);

    partnersdata.desc_al = content;
    partnersdata.desc_en = contenten;
    partnersdata.desc_sr = contentsr;

    let id;
    await axios
      .post("partners", partnersdata)
      .then((data) => {
        // console.log(data);
        id = data.data.id;
        // toast.success("News has been added successfully!");
        // window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    await axios
      .post(`partners/image/${id}`, formData)
      .then((data) => {
        // console.log(data);
        toast.success("Partner has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        // toast.error("Something went wrong!");
        window.history.back();
        console.log(err);
      });
  };

  return (
    <div className="partners p-5 text-left">
      <div className="page-title">
        <h1>Add Partners</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <input type="file" onChange={imageDataChange} multiple />

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli"
              onChange={name_al}
              defaultValue={partnersdata.name_al}
            />
            <ReactQuill
              theme="snow"
              value={content}
              onChange={desc_al}
              formats={formats}
              modules={modules}
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Naslov"
              onChange={name_sr}
              defaultValue={partnersdata.name_sr}
            />
            <ReactQuill
              theme="snow"
              value={contentsr}
              onChange={desc_sr}
              formats={formats}
              modules={modules}
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Title"
              onChange={name_en}
              defaultValue={partnersdata.name_en}
            />
            <ReactQuill
              theme="snow"
              value={contenten}
              onChange={desc_en}
              formats={formats}
              modules={modules}
            />
          </>
        )}

        <button type="submit">Add Partner</button>
      </form>
    </div>
  );
};

export default AddPartners;
