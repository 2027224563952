import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import Search from "../search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";

const SafetyReports = () => {
  const [safetyReports, setSafetyReports] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get("/safety-reports/all")
      .then((res) => {
        setSafetyReports(res?.data);
        setFiltered(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="safety-reports text-left">
      <div className="page-title">
        <h1>Safety Reports Management</h1>
      </div>
      <div className="row ml-n4 search">
        <div className="col-md-9">
          <Search
            data={safetyReports}
            title="SafetyReports"
            filtered={setFiltered}
            setPageNumber={setPageNumber}
          />
        </div>
        <div className="col-md-3">
          <Link to="/safety-reports/add" className="myButton">
            Add New Report
          </Link>
        </div>
      </div>

      <div className="listimi-tabela">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="listimi-head">
              <div className="row">
                <div className="col-1">ID</div>
                <div className="col-8">Content</div>
                <div className="col-1">Actions</div>
              </div>
            </div>
            {filtered
              .slice(pagesVisited, pagesVisited + productsPerPage)
              .map((report) => (
                <div className="listimi showme" key={report.id}>
                  <div className="row">
                    <div className="col-1">{report.id}</div>
                    <div className="col-8">{report.content.slice(0, 50) + "..."}</div>
                    <div className="col-1 actions">
                      {/* <Link
                        className="fa fa-trash text-danger"
                        to={`/safety-reports/delete/${report.id}`}
                      ></Link> */}
                      <Link
                        className="fa fa-eye text-primary ml-3"
                        to={`/safety-reports/edit/${report.id}`}
                      ></Link>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>

      <div className="row mt-5">
        <div className="col-12">
          {safetyReports.length > productsPerPage && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SafetyReports;
