import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditMisioniVizioni = (props) => {
  const [misionivizoni, editmisionivizioni] = useState({});
  const [useroles, adduseroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  // const [files, setFiles] = useState([]);

  useEffect(() => {
    axios
      .get("misionvision/id?id=" + props.match.params.id)
      .then((data) => {
        editmisionivizioni(data?.data);
        // console.log("userdata", data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const content_al = (value) => {
    setContent(value);
  };
  const content_sr = (value) => {
    setContentsr(value);
  };
  const content_en = (value) => {
    setContenten(value);
  };
  // const createdAt = (e) => {
  //   const ud = Object.assign({}, misionivizoni);
  //   ud.created_at = e.target.value;
  //   editmisionivizioni(ud);
  // };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    misionivizoni.content_al = content;
    misionivizoni.content_en = contenten;
    misionivizoni.content_sr = contentsr;

    // const formData = new FormData();
    // formData.append("image", image);
    let id;

    // console.log("misioni dhe vizioni", misionivizoni);

    await axios
      .put("misionvision", misionivizoni)
      .then((data) => {
        // console.log(data?.data);
        // aboutus(data?.data);
        // id = data.data.id;
        toast.success("Mission has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };
  return (
    <div className="misioni_vizoni mt-2 text-left ">
      <div className="page-title">
        <h1>Edit Mission and Vision</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              {/* <textarea placeholder="Përmbajtja" onChange={content_al} /> */}
              <ReactQuill
                theme="snow"
                defaultValue={misionivizoni?.content_al}
                onChange={content_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "sr" && (
            <>
              {/* <textarea placeholder="Sadrzaj" onChange={content_sr} /> */}
              <ReactQuill
                theme="snow"
                defaultValue={misionivizoni?.content_sr}
                onChange={content_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "en" && (
            <>
              {/* <textarea placeholder="Content" onChange={content_en} /> */}
              <ReactQuill
                theme="snow"
                defaultValue={misionivizoni?.content_en}
                onChange={content_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {/* <input type="date" onChange={createdAt} required /> */}

          <button type="submit">Edit</button>
        </form>
      )}
    </div>
  );
};

export default EditMisioniVizioni;
