import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditDocumentsPublications = (props) => {
  const [editDocuments, setEditDocuments] = useState({
    title_al: "",
    description_al: "",
    description_en: "",
    description_sr: "",
    pdf_link_al: null,
    pdf_link_en: null,
    pdf_link_sr: null,
  });

  console.log("is", props.match.params.id);
  const [languages, addlanguages] = useState("al");
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [fileNames, setFileNames] = useState({
    al: "",
    en: "",
    sr: "",
  });

  useEffect(() => {
    axios
      .get("documents_publications/" + props.match.params.id)
      .then((data) => {
        setEditDocuments(data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    setEditDocuments({
      ...editDocuments,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e, language) => {
    let fileKey;
    switch (language) {
      case "al":
        fileKey = "pdf_link_al";
        break;
      case "en":
        fileKey = "pdf_link_en";
        break;
      case "sr":
        fileKey = "pdf_link_sr";
        break;
      default:
        fileKey = "pdf_link_al";
    }

    setEditDocuments({
      ...editDocuments,
      [fileKey]: e.target.files[0],
    });
  };

  const description_al = (value) => {
    setContent(value);
  };
  const description_sr = (value) => {
    setContentsr(value);
  };
  const description_en = (value) => {
    setContenten(value);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", props.match.params.id);
    formData.append("title_al", editDocuments.title_al);
    formData.append("title_en", editDocuments.title_en);
    formData.append("title_sr", editDocuments.title_sr);
    formData.append("description_al", content);
    formData.append("description_en", contenten);
    formData.append("description_sr", contentsr);

    if (editDocuments.pdf_link_al) {
      formData.append("pdf_link_al", editDocuments.pdf_link_al);
    }
    if (editDocuments.pdf_link_en) {
      formData.append("pdf_link_en", editDocuments.pdf_link_en);
    }
    if (editDocuments.pdf_link_sr) {
      formData.append("pdf_link_sr", editDocuments.pdf_link_sr);
    }

    axios
      .put("/documents_publications", formData)
      .then((response) => {
        console.log(response.data);
        toast.success("Document has been updated successfully!");
        window.history.back();
      })
      .catch((error) => {
        console.error("Error updating document:", error);
        toast.error("Failed to update document. Please try again later.");
      });
  };

  return (
    <div className="legislation p-5 text-left">
      <div className="page-title">
        <h1>Edit Document</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={HandleSubmit}>
        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli AL"
              name="title_al"
              onChange={handleChange}
              defaultValue={editDocuments?.title_al}
            />

            <ReactQuill
              theme="snow"
              defaultValue={editDocuments?.description_al}
              onChange={description_al}
              formats={formats}
              modules={modules}
            />

            <input type="file"  className="mt-2"  onChange={(e) => handleFileChange(e, "al")} />
            {editDocuments.pdf_link_al && (
              <p>
                {typeof editDocuments.pdf_link_al === "object"
                  ? ""
                  : "Uploaded file:" +
                    editDocuments.pdf_link_al.split("\\").pop()}
              </p>
            )}
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Titulli SR"
              name="title_sr"
              onChange={handleChange}
              defaultValue={editDocuments?.title_sr}
            />
            <ReactQuill
              theme="snow"
              defaultValue={editDocuments?.description_sr}
              onChange={description_sr}
              formats={formats}
              modules={modules}
            />
            
            <input type="file" className="mt-2" onChange={(e) => handleFileChange(e, "sr")} />

            {editDocuments.pdf_link_sr && (
              <p>
                {typeof editDocuments.pdf_link_sr === "object"
                  ? ""
                  : "Uploaded file:" +
                    editDocuments.pdf_link_sr.split("\\").pop()}
              </p>
            )}
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Titulli EN"
              name="title_en"
              onChange={handleChange}
              defaultValue={editDocuments?.title_en}
            />
            <ReactQuill
              theme="snow"
              defaultValue={editDocuments?.description_en}
              onChange={description_en}
              formats={formats}
              modules={modules}
            />
            <input type="file"   className="mt-2" onChange={(e) => handleFileChange(e, "en")} />
            {editDocuments.pdf_link_en && (
              <p>
                {typeof editDocuments.pdf_link_en === "object"
                  ? ""
                  : "Uploaded file:" +
                    editDocuments.pdf_link_en.split("\\").pop()}
              </p>
            )}
          </>
        )}

        <button type="submit">Edit Document</button>
      </form>
    </div>
  );
};

export default EditDocumentsPublications;
