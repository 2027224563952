import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteReportCategory = (props) => {
  const [reportcategories, deletereportcategories] = useState({});
  const [languages, editlanguages] = useState("al");

  useEffect(() => {
    axios
      .get("report-categories/id?id=" + props.match.params.id)
      .then((res) => {
        deletereportcategories(res?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("report-categories/?id=" + reportcategories.id)
      .then((data) => {
        // console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };
  // console.log("dsdfasfafsasdfasfasfasfasf", reportcategories);

  return (
    <>
      <div className="report_categories p-5 text-left">
        <div className="page-title">
          <h1>Edit Report Categories</h1>
        </div>
        {/* {!isloading ? (
        <Loader />
      ) : ( */}
        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input
                placeholder="Titulli"
                value={reportcategories.name_al}
                disabled
              />
              <textarea
                placeholder="Përmbajtja"
                value={reportcategories.desc_al}
                disabled
              />
            </>
          )}
          {languages == "sr" && (
            <>
              <input
                placeholder="Naslov"
                disabled
                value={reportcategories.name_sr}
              />
              <textarea
                placeholder="Sadrzaj"
                value={reportcategories.desc_sr}
                disabled
              />
            </>
          )}
          {languages == "en" && (
            <>
              <input
                placeholder="Title"
                disabled
                value={reportcategories.name_en}
              />
              <textarea
                placeholder="Content"
                value={reportcategories.desc_en}
                disabled
              />
            </>
          )}

          <button type="submit">Delete Report Categorie</button>
        </form>
        {/* )} */}
      </div>
    </>
  );
};

export default DeleteReportCategory;
