import React, { useState, useEffect } from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "../../axios";
import * as XLSX from 'xlsx';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
)

const Employment = (props) => {

  const [byEmploymentStatus, setbyEmploymentStatus] = useState([]);

  useEffect(() => {
    axios
      .get(`statistics/byEmploymentStatus/${props?.xona}/${props?.xona2}`)
      .then((res) => {
        setbyEmploymentStatus(res?.data);
        props?.xona4();
        // console.log("LineChart", res?.data)             
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props?.xona3]);

  let employment = [];
  let number = [];

  byEmploymentStatus?.map(el => {
    employment.push(el.employment);
    number.push(el.number);
  });
  var data = {
    labels: employment, // kategorija
    datasets: [{
      label: 'Employment',
      data: number,  // numri
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
      ],
      borderWidth: 2
    }]
  };

  var options = {
    maintainAspectRatio: false,
    borderRadius: 10,
    // indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true
      }
    },
    legend: {
      labels: {
        fontSize: 26
      }
    }
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(byEmploymentStatus);
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Nga data:', props?.xona]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [['Deri në datë :', props?.xona2]], { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Employment');
    XLSX.writeFile(workbook, 'employment.xlsx');
};

  return (
    <>
      <div className="chart-title pt-5">
        <h3>Employment Chart </h3>
        <button onClick={() => exportToExcel(props?.xona, props?.xona2)} className="excel-btn"><i class="fas fa-file-excel pr-2"></i>Export to Excel</button>
      </div>

      <div>
        <Bar
          data={data}
          width={300}
          height={300}
          options={options}
        />
      </div>
    </>

  )

}

export default Employment;