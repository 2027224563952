import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteLinks = (props) => {
  const [link, deleteLink] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [languages, editlanguages] = useState("al");

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  useEffect(() => {
    axios
      .get("links/id?id=" + props.match.params.id)
      .then((data) => {
        deleteLink(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("links/?id=" + link.id)
      .then((data) => {
        // console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="links p-5 text-left">
      <div className="page-title">
        <h1>Delete Link</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete link with the following
        data:
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <input type="text" defaultValue={link.url} disabled />

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input defaultValue={link.name_al} disabled />
          </>
        )}
        {languages == "sr" && (
          <>
            <input defaultValue={link.name_sr} disabled />
          </>
        )}
        {languages == "en" && (
          <>
            <input value={link.name_en} disabled />
          </>
        )}

        <button type="submit">Delete Link</button>
      </form>
      {/* } */}
    </div>
  );
};

export default DeleteLinks;
