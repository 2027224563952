import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  


const DeleteDocumentsPublications = (props) => {
  const [deleteDocuments, setDeleteDocuments] = useState({});
  const [languages, editlanguages] = useState("al");


  const language = (lang) => {
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  useEffect(() => {
    axios
      .get("documents_publications/" + props.match.params.id)
      .then((data) => {
        setDeleteDocuments(data?.data);
        // console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .delete("documents_publications/" + deleteDocuments?.id)
      .then((data) => {
        window.history.back();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="events p-5 text-left">
      <div className="page-title">
        <h1>Delete Document</h1>
      </div>

      <div className="row mb-3 text">
        By clicking delete button you agree to delete events with the following
        data:
      </div>

      <form onSubmit={handleSubmit}>
       
        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli AL"
              name="title_al"
              defaultValue={deleteDocuments?.title_al}
              disabled
            />

            <ReactQuill
              theme="snow"
              defaultValue={deleteDocuments?.description_al}
              placeholder={"Write something awesome..."}
              formats={formats}
              modules={modules}
              disabled
            />
            
            <input
              className="url mt-2"
              placeholder="Url_al"
              name="pdf_link_al"
              defaultValue={deleteDocuments?.pdf_link_al}
              disabled
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Titulli SR"
              name="title_sr"
              defaultValue={deleteDocuments?.title_sr}
              disabled
            />
            <ReactQuill
              theme="snow"
              placeholder={"Write something awesome..."}
              name="description_sr"
              formats={formats}
              modules={modules}
              disabled
            />
            <input
              className="url mt-2"
              placeholder="Url_sr"
              name="pdf_link_sr"
              defaultValue={deleteDocuments?.pdf_link_sr}
              disabled
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Titulli EN"
              name="title_en"
              defaultValue={deleteDocuments?.title_en}
              disabled
            />
            <ReactQuill
              theme="snow"
              placeholder={"Write something awesome..."}
              name="description_en"
              formats={formats}
              modules={modules}
              disabled
            />
            <input
              className="url mt-2"
              placeholder="Url_al"
              name="pdf_link_al"
              defaultValue={deleteDocuments?.pdf_link_en}
              disabled
            />
          </>
        )}

        <button type="submit">Delete Document</button>
      </form>
    </div>
  );
};

export default DeleteDocumentsPublications;
