import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./case_status.scss";

const AddCaseStatus = (props) => {
  const [casestatus, addcasestatus] = useState({});
  //   const [isloading, setisloading] = useState(false);

  const status_al = (e) => {
    const ud = Object.assign({}, casestatus);
    ud.status_al = e.target.value;
    addcasestatus(ud);
  };
  const status_sr = (e) => {
    const ud = Object.assign({}, casestatus);
    ud.status_sr = e.target.value;
    addcasestatus(ud);
  };
  const status_en = (e) => {
    const ud = Object.assign({}, casestatus);
    ud.status_en = e.target.value;
    addcasestatus(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("case-status", casestatus)
      .then((data) => {
        // console.log(data);
        toast.success("Status has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="casestatus p-5 text-left">
      <div className="page-title">
        <h1>Add Case Status</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name Albanian"
          onChange={status_al}
          required
          title="This field is required"
        ></input>

        <input
          type="text"
          required
          title="This field is required"
          placeholder="Name Serbian"
          onChange={status_sr}
        ></input>

        <input
          type="text"
          required
          title="This field is required"
          placeholder="Name English"
          onChange={status_en}
        ></input>

        <button type="submit">Add Case</button>
      </form>
      {/* } */}
    </div>
  );
};
export default AddCaseStatus;
