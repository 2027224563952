import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./case_status.scss";

const EditCaseStatus = (props) => {
  const [munidata, addmunidata] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("case-status/id?id=" + props.match.params.id)
      .then((data) => {
        addmunidata(data.data);
        // console.log("munidata", data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const status_al = (e) => {
    const ud = Object.assign({}, munidata);
    ud.status_al = e.target.value;
    addmunidata(ud);
  };
  const status_sr = (e) => {
    const ud = Object.assign({}, munidata);
    ud.status_sr = e.target.value;
    addmunidata(ud);
  };
  const status_en = (e) => {
    const ud = Object.assign({}, munidata);
    ud.status_en = e.target.value;
    addmunidata(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("case-status", munidata)
      .then((data) => {
        toast.success(
          `Case Status ${munidata.status_en} has been edited successfully!`
        );
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="casestatus p-5 text-left">
      <div className="page-title">
        <h1>Edit Case Status</h1>
      </div>

      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Albanian</label>
          <input
            type="text"
            placeholder="status_al"
            onChange={status_al}
            defaultValue={munidata.status_al}
          ></input>

          <label>Serbian</label>
          <input
            type="text"
            placeholder="status_sr"
            onChange={status_sr}
            defaultValue={munidata.status_sr}
          ></input>

          <label>English</label>
          <input
            type="text"
            placeholder="status_en"
            onChange={status_en}
            defaultValue={munidata.status_en}
          ></input>

          <button type="submit">Edit Status</button>
        </form>
      )}
    </div>
  );
};
export default EditCaseStatus;
