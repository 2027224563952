import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";
import "./case_status.scss";

const DeleteCaseStatus = (props) => {
  const [munidata, addmunidata] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("case-status/id?id=" + props.match.params.id)
      .then((data) => {
        addmunidata(data.data);
        setisloading(true);
        // console.log("user", data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("case-status/?id=" + munidata.id)
      .then((data) => {
        toast.success(`Case ${munidata?.name_en} was deleted successfully`);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <div className="casestatus p-5 text-left">
      <div className="page-title">
        <h1>Delete Case Status</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete status with the following
        data:
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Albanian</label>
          <input type="text" defaultValue={munidata.status_al} disabled />
          <label>Serbian</label>
          <input type="text" defaultValue={munidata.status_sr} disabled />
          <label>English</label>
          <input type="text" value={munidata.status_en} disabled />

          <button type="submit">Delete Status</button>
        </form>
      )}
    </div>
  );
};
export default DeleteCaseStatus;
