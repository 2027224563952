import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import { toast } from "react-toastify";

const AddMunicipalities = (props) => {

    const [munidata, addmunidata] = useState({});
    const [userroles, adduserroles] = useState({});
    const [languages, addlanguages] = useState({});
    const [isloading, setisloading] = useState(false);


    // useEffect(() => {
    //     defValues();
    //     axios.get('user-roles/all').then(data => adduserroles(data.data))
    //         .catch(err => {
    //             toast.error('Something went wrong!')
    //             console.log(err)
    //         });
    //     axios.get('languages/all').then((data) => {
    //         addlanguages(data.data);
    //         setisloading(true)
    //     }).catch(err => {
    //         toast.error('Something went wrong!')
    //         console.log(err)
    //     });
    // }, [])

    const defValues = () => {
        const ud = Object.assign({}, munidata);
        ud.auth = 'initial-auth';
        ud.mobileauth = 'initial-auth';
        addmunidata(ud);
    }

    const name_al = e => {
        const ud = Object.assign({}, munidata);
        ud.name_al = e.target.value
        addmunidata(ud);
        // console.log('seeme',munidata)
    }
    const name_sr = e => {
        // console.log(e)
        const ud = Object.assign({}, munidata);
        ud.name_sr = e.target.value
        addmunidata(ud);
        // console.log('seeme',munidata)
    }
    const name_en = e => {
        // console.log(e)
        const ud = Object.assign({}, munidata);
        ud.name_en = e.target.value
        addmunidata(ud);
        // console.log('seeme',munidata)
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('municipalities', munidata)
            .then(data => {
                // console.log(data);
                toast.success('Municipality has been added successfully!')
                window.history.back();

            })
            .catch(err => {
                // toast.error('Something went wrong!')
                console.log(err)
            });

    }
    // console.log(userroles);
    // console.log(languages);

    return (
        <div className='municipalities p-5 text-left'>
            <div className='page-title'><h1>Add Municipality</h1></div>
            {/* {!isloading ? '' : */}
            <form onSubmit={handleSubmit}>

                <input type='text'
                    placeholder='Name Albanian'
                    onChange={name_al}
                    required
                    title="This field is required"
                ></input>
                <input type='text' required
                    title="This field is required" placeholder='Name Serbian' onChange={name_sr}></input>
                <input type='text' required
                    title="This field is required" placeholder='Name English' onChange={name_en}></input>

                <button type='submit'>Add Municipality</button>
            </form>
            {/* } */}
        </div>

    )
}
export default AddMunicipalities;
