
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Player } from "video-react";
import axios from "../../axios";
// import "..ShowFilesModal/modal.scss"

const ShowStatusModal = (props) => {
    const { id } = useParams();
    const [status, setStatus] = useState([]);
    const [item, setItem] = useState(null);



    const handleStatus = (e) => {
        const ud = Object.assign({}, item);
        ud.status_id = Number(e.target.value);
        setItem(ud);
    };
    const handleDesc = (e) => {
        const ud = Object.assign({}, item);
        ud.description = e.target.value;
        setItem(ud);
    };
    const handleDate = (e) => {
        const ud = Object.assign({}, item);
        ud.date = e.target.value;
        setItem(ud);
    };
    let userId = window.localStorage.getItem("id");
    // console.log('userId', item);

    const handleAdd = (e) => {
        e.preventDefault();
        axios.post(`/case-statuses/`, item)
            .then((res) => {
                // console.log('mireO', res.data);
                setTimeout(() => {
                    props?.getStatus();
                    props?.closeModalStatus();
                }, 300)
            })
            .catch((err) => {
                console.log('error', err);
            });
    }


    useEffect(() => {
        const ud = Object.assign({}, item);
        ud.case_id = Number(id);
        ud.created_by = Number(userId);
        setItem(ud);
    }, [])

    useEffect(() => {
        axios
            .get(`/case-status/all`)
            .then((res) => {
                setStatus(res?.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);
    return (
        <>

            <div className="show_header">
                {/* <h3>Edit Status</h3> */}
                <button className=" myButton close_modal  fa fa-close " onClick={props?.closeModalStatus} />
            </div>
            <div className="statusModal">
                <div className="container editStatus">

                    <h5 className="mt-4 mb-4">
                        Add Status
                    </h5>



                    <form className="form" onSubmit={handleAdd}>
                        <select className="form-control mb-2" name="status" onChange={handleStatus} required>
                        <option value=""  selected disabled>Selekto Statusin</option>
                            {status?.map((el) => {
                                return (
                                    <option value={el?.id}>{el?.status_al}</option>

                                );
                            })}
                        </select>
                        <textarea className="form-control mb-2" placeholder="Mesazhi" onChange={handleDesc} required></textarea>
                        <input type="date" className="form-control mb-2" onChange={handleDate} required></input>
                        <button type="submit" className=" mb-2 btnEdit"> Save</button>
                    </form>
                </div>


            </div>
        </>
    )
}

export default ShowStatusModal