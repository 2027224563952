import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const EditLabelTranslations = (props) => {
  const [labeltranslations, addlabeltranslations] = useState({});
  const [userroles, adduserroles] = useState({});
  const [languages, addlanguages] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    // defValues();

    axios
      .get("labels-translations/id?id=" + props.match.params.id)
      .then((data) => {
        addlabeltranslations(data.data);
        // console.log("translation", data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  // const defValues = () => {
  //     const ud = Object.assign({}, labeltranslations);
  //     ud.auth = 'initial-auth';
  //     ud.mobileauth = 'initial-auth';
  //     addlabeltranslations(ud);
  // }

  const type = (e) => {
    const ud = Object.assign({}, labeltranslations);
    ud.type = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const key = (e) => {
    const ud = Object.assign({}, labeltranslations);
    ud.key = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const content_al = (e) => {
    const ud = Object.assign({}, labeltranslations);
    ud.content_al = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const content_sr = (e) => {
    // console.log(e)
    const ud = Object.assign({}, labeltranslations);
    ud.content_sr = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };
  const content_en = (e) => {
    // console.log(e)
    const ud = Object.assign({}, labeltranslations);
    ud.content_en = e.target.value;
    addlabeltranslations(ud);
    // console.log('seeme',labeltranslations)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("labels-translations", labeltranslations)
      .then((data) => {
        toast.success(
          `Label ${labeltranslations.content_en} has been edited successfully!`
        );
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="labels_translations p-5 text-left">
      <div className="page-title">
        <h1>Edit Label Translation</h1>
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Type</label>
          <select
            type="text"
            placeholder="content_al"
            onChange={type}
            defaultValue={labeltranslations.type}
          >
            <option value={null}>Select Type</option>
            <option value={"Mobile"}>Mobile</option>
            <option value={"Web"}>Web</option>
          </select>
          <label>Key</label>
          <input
            type="text"
            placeholder="content_al"
            onChange={key}
            defaultValue={labeltranslations.key}
          ></input>
          <label>Albanian</label>
          <input
            type="text"
            placeholder="content_al"
            onChange={content_al}
            defaultValue={labeltranslations.content_al}
          ></input>
          <label>Serbian</label>
          <input
            type="text"
            placeholder="content_sr"
            onChange={content_sr}
            defaultValue={labeltranslations.content_sr}
          ></input>
          <label>English</label>
          <input
            type="text"
            placeholder="content_en"
            onChange={content_en}
            defaultValue={labeltranslations.content_en}
          ></input>
          <button type="submit">Edit Label</button>
        </form>
      )}
    </div>
  );
};
export default EditLabelTranslations;
