import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const EditLinks = (props) => {
  const [linksdata, editlinksdata] = useState({});
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("links/id?id=" + props.match.params.id)
      .then((data) => {
        editlinksdata(data?.data);
        setisloading(true);
        // console.log("linksdata", data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.name_al = e.target.value;
    editlinksdata(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.name_sr = e.target.value;
    editlinksdata(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.name_en = e.target.value;
    editlinksdata(ud);
  };
  const url = (e) => {
    const ud = Object.assign({}, linksdata);
    ud.url = e.target.value;
    editlinksdata(ud);
  };
  const language = (lang) => {
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("links", linksdata)
      .then((res) => {
        // console.log(res?.data);
        toast.success("Link has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="links p-5 text-left">
      <div className="page-title">
        <h1>Edit Link</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <input type="text" onChange={url} defaultValue={linksdata.url} />

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli"
              onChange={name_al}
              defaultValue={linksdata.name_al}
            />
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Naslov"
              onChange={name_sr}
              defaultValue={linksdata.name_sr}
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Title"
              onChange={name_en}
              value={linksdata.name_en}
            />
          </>
        )}

        <button type="submit">Edit Link</button>
      </form>
      {/* } */}
    </div>
  );
};

export default EditLinks;
